import React, { useState, useEffect, useCallback } from 'react'
import ReactDOM from 'react-dom'

import 'isomorphic-fetch'

import Error from './helpers/Error'
import Loader from './helpers/Loader'
import EventsList from './components/EventsList'

const EventsOverview = () => {
  const [hasError, setErrors] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [isLoadingMore, setLoadingMore] = useState(false);
  const [events, setEvents] = useState();
  const [filter, setFilter] = useState(false);
  const [limit, setLimit] = useState(5);
  const [length, setLength] = useState();

  const loadData = () => {
    fetch('/wp-json/wp/v2/event/?per_page=100&orderby=date')
      .then((response) => response.json())
      .then((data) => {
        setEvents(data)
        setLength(data.length)
        setLoading(false)
      })
      .catch((error) => setErrors(error))
  }

  const handleFilterButtonClick = useCallback((event) => {
    const { target } = event
    const filterButtons = [...document.querySelectorAll('.event-filter-button')]
    filterButtons.forEach((button) => {
      button.classList.remove('active')
    })
    target.classList.add('active')
    const filterAttribute = target.getAttribute('data-location')
    if (filterAttribute !== false && filterAttribute !== 'false') {
      const locationID = parseInt(filterAttribute, 10)
      setFilter(locationID)
    } else {
      setFilter(false)
    }
    event.preventDefault()
  }, [])

  useEffect(() => {
    loadData()

    const filterButtons = [...document.querySelectorAll('.event-filter-button')]
    filterButtons.forEach((button) => {
      button.addEventListener('click', handleFilterButtonClick)
    })

    return () => {
      filterButtons.forEach((button) => {
        button.removeEventListener('click', handleFilterButtonClick)
      })
    }
  }, [handleFilterButtonClick])

  const handleScroll = () => {
    const elementPosition = document.getElementById('eventsOverview').getBoundingClientRect()
    const positionFromTop = elementPosition.bottom + window.scrollY
    const scrolledTarget = window.innerHeight + window.pageYOffset - 300

    if (limit < length) {
      if (positionFromTop > scrolledTarget && !isLoadingMore) {
        setLoadingMore(true)
        setTimeout(() => {
          setLimit(limit + 5)
          setLoadingMore(false)
        }, 1000)
      }
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true })
    return () => window.removeEventListener('scroll', handleScroll)
  }, [handleScroll, isLoadingMore, limit, length])

  return (
    <>
      {hasError && <Error />}
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <EventsList
            events={events}
            filter={filter}
            limit={limit}
            handleFilterButtonClick={handleFilterButtonClick}
          />
          {isLoadingMore && <Loader />}
        </>
      )}
    </>
  )
}

const domContainer = document.getElementById('eventsOverview')
if (domContainer) {
  // eslint-disable-next-line react/jsx-props-no-spreading
  ReactDOM.render(<EventsOverview />, domContainer)
}
