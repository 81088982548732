import React, { useState, useEffect } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import StickyBox from 'react-sticky-box'
import PriceBox from './components/PriceBox'
import FormBox from './components/FormBox'

const App = (props) => {
  const { prices, grundangebot, apero, zusatzleistungen } = props
  const pricesObject2 = JSON.parse(prices)
  const maxPeople = parseInt(pricesObject2[pricesObject2.length - 1].to, 10)
  const minPeople = parseInt(pricesObject2[0].from, 10)
  const startPeople = minPeople + (maxPeople - minPeople) / 2
  const startPrice = parseInt(
    pricesObject2.find((obj) => {
      return obj.from <= startPeople && obj.to >= startPeople
    }).price,
    10
  )
  const [people, setPeople] = useState(startPeople)
  const [pricePerPerson, setPricePerPerson] = useState(startPrice)
  const [extraPerPerson, setExtraPerPerson] = useState(0)
  const [zusatzleistungenPrice, setZusatzleistungenPrice] = useState(0)
  const [formdata, setFormdata] = useState([])

  const calculateZusatzleistungen = () => {
    // Calculate the extra price on top of total
    if (formdata.length) {
      const personItems = formdata.filter((item) => item.type === 'total')
      if (personItems.length) {
        const extraPrice = personItems
          .map((item) => Number(item.price))
          .reduce((prev, next) => prev + next)
        return Number(extraPrice)
      }
      return Number(0)
    }
    return Number(0)
  }

  const calculateExtraPrice = () => {
    // Calculate the extra price per person
    if (formdata.length) {
      const personItems = formdata.filter((item) => item.type === 'person')
      if (personItems.length) {
        const extraPrice = personItems
          .map((item) => Number(item.price))
          .reduce((prev, next) => prev + next)
        return Number(extraPrice)
      }
      return Number(0)
    }
    return Number(0)
  }

  const findPricePerPerson = (value) => {
    // Find basic price per person in range of prices
    const pricesObject = JSON.parse(prices)
    const priceIndex = pricesObject.findIndex((item) => item.from <= value && item.to >= value)
    return Number(pricesObject[priceIndex].price)
  }

  const handleInput = (name, value, price, type) => {
    // Handle all radio and checkbox inputs

    // Check if input already exists in state
    const inputIndex = formdata.findIndex((input) => input.name === name)

    if (inputIndex !== -1) {
      const formdataCopy = formdata

      if (value === false) {
        // If it is a checkbox which is getting unchecked, remove input from state
        formdataCopy.splice(inputIndex, 1)
      } else {
        // Update existing input in state
        formdataCopy[inputIndex] = { name, value, price, type }
      }

      setFormdata(formdataCopy)
      setExtraPerPerson(calculateExtraPrice)
      if (type === 'total') {
        setZusatzleistungenPrice(calculateZusatzleistungen)
      }
    } else {
      // Save new input to state
      const newInput = { name, value, price, type }
      setFormdata((oldState) => [...oldState, newInput])
      setExtraPerPerson(calculateExtraPrice)
      if (type === 'total') {
        setZusatzleistungenPrice(calculateZusatzleistungen)
      }
    }
  }

  const handleSliderChange = (value) => {
    // Handle change of slider
    setPeople(value)
    setPricePerPerson(findPricePerPerson(value))
  }

  const buildStringForHiddenField = (data) => {
    let string = ''
    string += data.map((input) => {
      return `${input.name}: ${input.value}`
    })
    return string
  }

  useEffect(() => {
    // Do stuff every time formdata changes
    setExtraPerPerson(calculateExtraPrice)
    setZusatzleistungenPrice(calculateZusatzleistungen)
  }, [formdata])

  return (
    <>
      <div className='form-boxes'>
        <FormBox
          data={JSON.parse(grundangebot)}
          handleInput={handleInput}
          calculationMode='person'
        />
        <FormBox data={JSON.parse(apero)} handleInput={handleInput} calculationMode='person' />
        <FormBox
          data={JSON.parse(zusatzleistungen)}
          handleInput={handleInput}
          calculationMode='total'
        />
      </div>
      <StickyBox className='price-box-wrapper'>
        <PriceBox
          prices={JSON.parse(prices)}
          people={people}
          pricePerPerson={pricePerPerson}
          extraPerPerson={extraPerPerson}
          zusatzleistungenPrice={zusatzleistungenPrice}
          handleSliderChange={handleSliderChange}
        />
      </StickyBox>
      <div className='hidden-fields'>
        {/* This is a workaround to pass data to the ninja forms hidden fields, because otherwise ninja forms wouldn't detect the changed data ...
            Passing the data in the file react2ninjaforms.js via jQuery to the ninja forms hidden fields. */}
        <input
          type='hidden'
          id='react2ninjaforms-formdata'
          value={buildStringForHiddenField(formdata)}
        />
        <input type='hidden' id='react2ninjaforms-people' value={people} />
        <input
          type='hidden'
          id='react2ninjaforms-pricePerPerson'
          value={pricePerPerson + extraPerPerson}
        />
        <input
          type='hidden'
          id='react2ninjaforms-totalPrice'
          value={people * (pricePerPerson + extraPerPerson) + zusatzleistungenPrice}
        />
      </div>
    </>
  )
}

App.propTypes = {
  prices: PropTypes.string.isRequired,
  grundangebot: PropTypes.string.isRequired,
  apero: PropTypes.string.isRequired,
  zusatzleistungen: PropTypes.string.isRequired
}

const domContainer = document.getElementById('weddingPicker')
if (domContainer) {
  // eslint-disable-next-line react/jsx-props-no-spreading
  ReactDOM.render(<App {...domContainer.dataset} />, domContainer)
}
