import React from 'react'
import PropTypes from 'prop-types'

import Radios from './inputs/Radios'
import Checkbox from './inputs/Checkbox'
import Text from './inputs/Text'
import Title from './inputs/Title'

const FormBox = (props) => {
  const { data, handleInput, calculationMode } = props
  let id = 0
  return (
    <div className='form-box'>
      {data.map((inputdata) => {
        id += 1
        if (inputdata.acf_fc_layout === 'checkbox') {
          return (
            <Checkbox
              key={id}
              data={inputdata}
              handleInput={handleInput}
              calculationMode={calculationMode}
            />
          )
        }
        if (inputdata.acf_fc_layout === 'radios') {
          return (
            <Radios
              key={id}
              data={inputdata}
              handleInput={handleInput}
              calculationMode={calculationMode}
            />
          )
        }
        if (inputdata.acf_fc_layout === 'text') {
          return <Text key={id} data={inputdata} />
        }
        if (inputdata.acf_fc_layout === 'title') {
          return <Title key={id} data={inputdata} />
        }
        return null
      })}
    </div>
  )
}

FormBox.propTypes = {
  data: PropTypes.array.isRequired,
  handleInput: PropTypes.func.isRequired,
  calculationMode: PropTypes.string.isRequired
}

export default FormBox
