import React from 'react'
import PropTypes from 'prop-types'

const Checkbox = (props) => {
  const { data, handleInput, calculationMode } = props
  const id = data.label.toLowerCase().replace(/\s/g, '')
  return (
    <div className='input--checkbox'>
      <input
        type='checkbox'
        id={id}
        name={data.label}
        value={data.label}
        data-price={data.price}
        onChange={(e) =>
          handleInput(e.target.name, e.target.checked, e.target.dataset.price, calculationMode)
        }
      />
      <label htmlFor={id}>
        {data.label}
        {data.price && <span className='price'> + CHF {data.price}.–</span>}
      </label>
      <div className='description'>{data.description}</div>
    </div>
  )
}

Checkbox.propTypes = {
  data: PropTypes.object.isRequired,
  handleInput: PropTypes.func.isRequired,
  calculationMode: PropTypes.string.isRequired
}

export default Checkbox
