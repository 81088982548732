import axios from 'axios'

class CookieBox {
  constructor(config) {
    console.log('new Cookiebox')
    this.section = config.section
    this.acceptAllButton = this.section.querySelector('#cookieboxAcceptAllButton')
    this.acceptMinimumButton = this.section.querySelector('#cookieboxAcceptMinimumButton')

    this.addEventListeners()
  }

  addEventListeners = () => {
    this.acceptAllButton.addEventListener('click', this.submitForm)
    this.acceptMinimumButton.addEventListener('click', this.submitForm)
  }

  submitForm = (e) => {
    e.preventDefault()

    const button = e.currentTarget

    const ajaxUrl = `/wp-json/api/cookiebox/`

    const data = {
      value: button.dataset.value
    }

    console.log(data)

    axios
      .post(ajaxUrl, data, {
        headers: this.headers
      })
      .then(() => {
        location.reload()
      })
      .catch((error) => {
        console.log(error)
      })
  }
}

export default CookieBox
