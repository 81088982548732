import React from 'react'
import PropTypes from 'prop-types'

const Title = (props) => {
  const { data } = props
  return (
    <div className='input--text'>
      <h5>{data.title}</h5>
    </div>
  )
}

Title.propTypes = {
  data: PropTypes.object.isRequired
}

export default Title
