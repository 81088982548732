import React from 'react'
import PropTypes from 'prop-types'

import dayjs from 'dayjs'
import 'dayjs/locale/de'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'

import Event from './Event'

dayjs.extend(customParseFormat).extend(isSameOrAfter).locale('de')

const EventsList = (props) => {
  const { events, filter, limit, handleFilterButtonClick } = props
  const eventsInTheFuture = events.filter((event) =>
    dayjs(event.acf.date, 'DD.MM.YYYY').isSameOrAfter(dayjs(), 'day')
  )
  const eventsSorted = eventsInTheFuture.sort(
    (a, b) => dayjs(a.acf.date, 'DD.MM.YYYY') - dayjs(b.acf.date, 'DD.MM.YYYY')
  )
  let eventsToDisplay = eventsSorted
  if (filter !== false) {
    eventsToDisplay = eventsSorted.filter((event) => event.acf.location[0].term_id === filter)
  }
  return (
    <>
      {eventsToDisplay.slice(0, limit).map((event) => (
        <Event
          key={event.id}
          event={event}
          handleFilterButtonClick={handleFilterButtonClick}
          className='event'
        />
      ))}
    </>
  )
}

EventsList.propTypes = {
  events: PropTypes.array.isRequired,
  filter: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  limit: PropTypes.number.isRequired,
  handleFilterButtonClick: PropTypes.func.isRequired
}

EventsList.defaultProps = {
  filter: false
}

export default EventsList
