import React from 'react'
import PropTypes from 'prop-types'

const Radios = (props) => {
  const { data, handleInput, calculationMode } = props
  return (
    <div className='input--radios'>
      <p className='radios__label'>{data.label}</p>
      <fieldset>
        {data.options.map((option) => {
          const id = `${data.label
            .toLowerCase()
            .replace(/\s/g, '')}-${option.label.toLowerCase().replace(/\s/g, '')}`
          return (
            <div className='radio' key={id}>
              <input
                type='radio'
                id={id}
                name={data.label}
                value={option.label}
                data-price={option.price}
                onChange={(e) =>
                  handleInput(
                    e.target.name,
                    e.target.value,
                    e.target.dataset.price,
                    calculationMode
                  )
                }
              />
              <label htmlFor={id}>
                {option.label}
                {option.price && <span className='price'> + CHF {option.price}.–</span>}
              </label>
              <div className='description'>{option.description}</div>
            </div>
          )
        })}
      </fieldset>
    </div>
  )
}

Radios.propTypes = {
  data: PropTypes.object.isRequired,
  handleInput: PropTypes.func.isRequired,
  calculationMode: PropTypes.string.isRequired
}

export default Radios
