import React from 'react'
import PropTypes from 'prop-types'

import dayjs from 'dayjs'
import 'dayjs/locale/de'
import customParseFormat from 'dayjs/plugin/customParseFormat'

dayjs.extend(customParseFormat).locale('de')

const ListItem = (props) => {
  const { event, handleFilterButtonClick } = props
  const description = event.acf.description ? `${event.acf.description}, ` : null

  const genre = event.acf.genre ? `${event.acf.genre}, ` : null

  const time = event.acf['doors-time']
    ? `, ab ${event.acf['doors-time']} Uhr`
    : `, ab ${event.acf['start-time']} Uhr`

  const presalePrice = event.acf['presale-price']
    ? `, ${event.acf['presale-price']} / ${event.acf.price}`
    : null
  const price = !event.acf['presale-price'] && event.acf.price ? `, ${event.acf.price}` : null

  const age = event.acf.age ? `, ${event.acf.age}` : null

  const location = event.acf.location ? event.acf.location[0].slug : ''
  const termId = event.acf.location ? event.acf.location[0].term_id : ''
  const locationName = event.acf.location ? event.acf.location[0].name : ''

  const htmlDecode = (content) => {
    const e = document.createElement('div')
    e.innerHTML = content
    return e.childNodes.length === 0 ? '' : e.childNodes[0].nodeValue
  }

  const titleElement = (
    // eslint-disable-next-line
    <span dangerouslySetInnerHTML={{ __html: htmlDecode(event.title.rendered) }} />
  )

  return (
    <div className='event'>
      <div className='event__date'>
        {dayjs(event.acf.date, 'DD.MM.YYYY').format('dddd, D. MMMM YYYY')}
      </div>
      <br />
      <h2 className='event__title'>
        {titleElement}
        {event.acf.image ? (
          <a className='event__image' href={event.link} title={event.title.rendered}>
            <img width='150' height='150' alt='test' src={event.acf.image.sizes.thumbnail} />
          </a>
        ) : null}
      </h2>
      <div className='event__details'>
        {description}
        {genre}
        <button
          onClick={handleFilterButtonClick}
          type='button'
          className={`event-filter-button bgcolor--${location}`}
          data-location={termId}>
          {locationName}
        </button>
        {time}
        {presalePrice}
        {price}
        {age},{' '}
        <a href={event.link} title={event.title.rendered}>
          Details ansehen
        </a>
      </div>
    </div>
  )
}

ListItem.propTypes = {
  event: PropTypes.object.isRequired,
  handleFilterButtonClick: PropTypes.func.isRequired
}

export default ListItem
