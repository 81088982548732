const giphy = require('giphy-api')({
  apiKey: 'Qfey7WTbx206XiS2R68ABuOMOScfiPqW',
  https: true
})

class Pencil {
  constructor() {
    this.currentGifUrl = ''
    this.gifImg = document.querySelector('.pencil__gif')
    this.shuffleButton = document.querySelector('#getNewGif')
    this.texts = [...document.querySelectorAll('.pencil__textWrapper h1 span')]
    this.currentTextIndex = 0

    this.getGif()

    this.addEventListeners()
  }

  addEventListeners = () => {
    this.shuffleButton.addEventListener('click', this.onClickShuffleButton)
  }

  onClickShuffleButton = () => {
    this.getGif()
  }

  getGif = () => {
    giphy
      .random({
        tag: 'pencil',
        rating: 'pg-13'
      })
      .then((res) => {
        this.currentGifUrl = res.data.images.original.url

        if ('original' in res.data.images) {
          this.setGif()
        } else {
          this.getGif()
        }
      })
  }

  setGif = () => {
    this.gifImg.src = this.currentGifUrl
    this.setText()
  }

  setText = () => {
    this.currentTextIndex += 1
    if (this.texts.length - 1 < this.currentTextIndex) {
      this.currentTextIndex = 0
    }
    this.texts.forEach((text, key) => {
      /* eslint-disable no-param-reassign */
      text.style.display = 'none'
      if (key === this.currentTextIndex) {
        text.style.display = 'block'
      }
    })
  }
}

export default Pencil
