import React from 'react'
import PropTypes from 'prop-types'
import Slider from 'react-rangeslider'

const PriceBox = (props) => {
  const {
    prices,
    people,
    pricePerPerson,
    extraPerPerson,
    zusatzleistungenPrice,
    handleSliderChange
  } = props
  const lowest = Number(prices[0].from)
  const highest = Number(prices[prices.length - 1].to)

  const numberWithSeparator = (number) => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "'")
  }

  const pricePerPersonWithExtra = pricePerPerson + extraPerPerson
  const totalPrice = people * (pricePerPerson + extraPerPerson) + zusatzleistungenPrice

  return (
    <div className='price-box'>
      <h5>Anzahl Personen</h5>
      <Slider
        value={people}
        orientation='horizontal'
        handleLabel={people.toString()}
        tooltip={false}
        min={lowest}
        max={highest}
        onChange={(value) => handleSliderChange(Number(value))}
      />
      <div className='price-box__person'>
        <span className='price-box__label'>Preis pro Person</span>
        <span className='price-box__value'>CHF {pricePerPersonWithExtra}.–</span>
      </div>
      {zusatzleistungenPrice > 0 && (
        <div className='price-box__zusatz'>
          <span className='price-box__label'>Zusatzleistungen</span>
          <span className='price-box__value'>
            CHF {numberWithSeparator(zusatzleistungenPrice)}.–
          </span>
        </div>
      )}
      <div className='price-box__total'>
        <span className='price-box__label'>Total</span>
        <span className='price-box__value'>CHF {numberWithSeparator(totalPrice)}.–</span>
      </div>
    </div>
  )
}

PriceBox.propTypes = {
  prices: PropTypes.array.isRequired,
  people: PropTypes.number.isRequired,
  pricePerPerson: PropTypes.number.isRequired,
  extraPerPerson: PropTypes.number.isRequired,
  zusatzleistungenPrice: PropTypes.number.isRequired,
  handleSliderChange: PropTypes.func.isRequired
}

export default PriceBox
