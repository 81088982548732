import '../styles/main.scss'

import hoverintent from 'hoverintent'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'

import './EventsOverview/App'
import './WeddingPicker/App'
import Pencil from './Pencil'
import Runner from './Runner'
import CookieBox from './CookieBox'

if (module.hot) module.hot.accept()

document.addEventListener('DOMContentLoaded', function DOMContentLoaded() {
  // Detect touch device
  //----------------------------------------------------------
  const isTouch = 'ontouchstart' in document.documentElement !== false

  const cookieBox = document.querySelector('div.cookiebox')
  if (cookieBox) {
    new CookieBox({
      section: cookieBox
    })
  }

  // Header dropdown menus
  //----------------------------------------------------------
  const menuElements = [...document.querySelectorAll('.header-menu .menu-item-has-children')]
  const menuLinkElements = [
    ...document.querySelectorAll('.header-menu .menu-item-has-children > a')
  ]

  const openSubmenu = (element) => {
    element.classList.add('submenu-is-open')
  }
  const closeSubmenu = (element) => {
    element.classList.remove('submenu-is-open')
  }

  // Regular devices
  if (!isTouch) {
    document.body.classList.add('no-touch')
    menuElements.forEach((element) => {
      hoverintent(
        element,
        () => {
          openSubmenu(element)
        },
        () => {
          closeSubmenu(element)
        }
      )
    })
    // Prevent links on parents
    menuLinkElements.forEach((element) => {
      element.addEventListener('click', function menuLinkParentClick(event) {
        event.preventDefault()
      })
    })
  }

  // Touch devices
  if (isTouch) {
    document.body.classList.add('touch')
    menuLinkElements.forEach((element) => {
      element.addEventListener('click', function menuLinkClick(event) {
        menuElements.forEach((node) => {
          node.classList.remove('submenu-is-open')
        })
        element.parentNode.classList.add('submenu-is-open')
        event.preventDefault()
      })
    })
  }

  // Mobile menu
  //----------------------------------------------------------

  const mobileMenu = document.getElementById('mobile-menu')
  const openMobileMenuButton = document.getElementById('open-mobile-menu')
  const closeMobileMenuButton = document.getElementById('close-mobile-menu')

  openMobileMenuButton.addEventListener(
    'touchstart',
    function openMobileMenu(event) {
      mobileMenu.classList.add('mobile-menu-is-open')
      disableBodyScroll(mobileMenu)
      event.preventDefault()
    },
    { passive: true }
  )

  closeMobileMenuButton.addEventListener(
    'touchstart',
    function closeMobileMenu(event) {
      mobileMenu.classList.remove('mobile-menu-is-open')
      enableBodyScroll(mobileMenu)
      event.preventDefault()
    },
    { passive: true }
  )

  // Mobile dropdown menu
  //----------------------------------------------------------

  const mobileDropdownItems = [
    ...document.querySelectorAll('.mobile-menu .menu-item-has-children > a')
  ]

  const handleMobileDropdown = (event) => {
    const listItem = event.currentTarget.parentNode
    if (listItem.classList.contains('submenu-is-open')) {
      listItem.classList.remove('submenu-is-open')
    } else {
      mobileDropdownItems.forEach((item) => {
        item.parentNode.classList.remove('submenu-is-open')
      })
      listItem.classList.add('submenu-is-open')
    }
    event.preventDefault()
  }

  mobileDropdownItems.forEach((link) => {
    link.addEventListener('touchstart', handleMobileDropdown, { passive: false })
  })

  // Stacked image gallery
  //----------------------------------------------------------
  const imageGalleries = [...document.querySelectorAll('.imagegallery')]

  const handleClickOnGallery = (event) => {
    const current = event.currentTarget.getElementsByClassName('image--active')[0]
    let next = current.nextElementSibling
    if (!next) {
      const first = event.currentTarget.getElementsByClassName('image')[0]
      next = first
    }
    current.classList.remove('image--active')
    next.classList.add('image--active')
    event.preventDefault()
  }

  // Regular devices
  if (!isTouch) {
    imageGalleries.forEach((gallery) => {
      gallery.addEventListener('click', handleClickOnGallery)
    })
  }

  // Touch devices
  if (isTouch) {
    imageGalleries.forEach((gallery) => {
      gallery.addEventListener('touchstart', handleClickOnGallery, { passive: true })
    })
  }

  // Change grid layout for event images
  //----------------------------------------------------------
  const gridLayouts = [...document.querySelectorAll('.events-grid')]

  const getNewRandomNumber = (currentNumber = 0) => {
    const maxRandomNumber = 6 // This should match the amount of grid layouts that are available (file: events.scss)
    let randomNumber
    while (true) {
      randomNumber = Math.floor(Math.random() * maxRandomNumber) + 1
      if (randomNumber !== parseInt(currentNumber, 10)) {
        break
      }
    }
    return randomNumber
  }

  const handleClickOnGrid = (event) => {
    const current = event.currentTarget.getAttribute('data-layout')
    const randomOnClick = getNewRandomNumber(current)
    event.currentTarget.classList.remove(`grid-layout--${current}`)
    event.currentTarget.classList.add(`grid-layout--${randomOnClick}`)
    event.currentTarget.setAttribute('data-layout', randomOnClick)
    event.preventDefault()
  }

  gridLayouts.forEach((grid) => {
    const randomOnLoad = getNewRandomNumber()
    grid.classList.add(`grid-layout--${randomOnLoad}`)
    grid.setAttribute('data-layout', randomOnLoad)

    // Regular devices
    if (!isTouch) {
      grid.addEventListener('click', handleClickOnGrid)
    }

    // Touch devices
    if (isTouch) {
      grid.addEventListener('touchstart', handleClickOnGrid, { passive: true })
    }
  })

  if (document.querySelector('body').classList.contains('page-id-11391')) {
    /* eslint-disable no-new */
    new Pencil()
  }

  if (document.querySelector('body').classList.contains('page-id-11749')) {
    /* eslint-disable no-new */
    new Runner('.interstitial-wrapper')

    const main = document.querySelector('main.wrapper')
    const header = document.querySelector('header')
    const resizeMain = () => {
      const headerRect = header.getBoundingClientRect()
      const windowHeight = window.innerHeight
      main.style.height = `${(windowHeight - headerRect.height) * 0.65}px`
    }
    resizeMain()
  }
})
